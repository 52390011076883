import React from "react";

import { graphql } from "gatsby";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import Divider from "components/Divider";
import PageContainer from "components/PageContainer";
import PrivacyContainer from "components/PrivacyContainer";
import SEO from "components/SEO";

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <PrivacyContainer>
        <PrivacyContainer.Content>
          <PrivacyContainer.ContentSection>
            <Divider icon={<h1>{t("Privacy Policy")}</h1>} color="white" />
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>{t("Introduction")}</h4>
            <p>
              <Trans
                t={t}
                i18nKey="Qantev SAS is an AI and Machine Learning company."
              >
                <span>Qantev SAS</span> is an AI and Machine Learning company.
              </Trans>
            </p>
            <p>{t("privacyPolicyIntroduction")}</p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>1. {t("Data Protection Officer")}</h4>
            <p>
              {t("dataProtectionOfficerDescription")}{" "}
              <a href="mailto:privacy@qantev.com">privacy@qantev.com</a>
            </p>
          </PrivacyContainer.ContentSection>
          <h4>
            2. {t("How we collect and use (process) your personal information")}
          </h4>
          <p>
            {t(
              "Qantev does not collect personal information about its website visitors. We do not sell personal information to anyone and do not share it with third parties."
            )}
          </p>
          <PrivacyContainer.ContentSection>
            <h4>3. {t("Use of the Qantev Website")}</h4>
            <p>{t("useOfQantevWebsiteDescription")}</p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>4. {t("Cookies and tracking technologies")}</h4>
            <p>
              {t(
                "Qantev does not use any cookies and tracking technologies on its website."
              )}
            </p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>5. {t("Sharing information with third parties")}</h4>
            <p>
              {t(
                "Qantev does not collect any personal information from you when you visit our website. Therefore, we do not share any information with third parties, nor engage third parties to send information to you."
              )}
            </p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>6. {t("Transferring personal data to France")}</h4>
            <p>
              {t(
                "Qantev does not store nor transfer any personal data. Since it was founded, Qantev has received zero government requests for information. For more information or if you have any questions, please contact us at"
              )}{" "}
              <a href="mailto:privacy@qantev.com">privacy@qantev.com</a>
            </p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>7. {t("Data Subject rights")}</h4>
            <p>
              {t(
                "The European Union’s General Data Protection Regulation (GDPR) and other countries’ privacy laws provide certain rights for data subjects."
              )}
            </p>
            <p>{t("Data Subject rights under GDPR include the following:")}</p>
            <ul>
              <li>{t("Right to be informed")}</li>
              <li>{t("Right of access")}</li>
              <li>{t("Right to rectification")}</li>
              <li>{t("Right to erasure")}</li>
              <li>{t("Right to restrict processing")}</li>
              <li>{t("Right of data portability")}</li>
              <li>{t("Right to object")}</li>
              <li>
                {t(
                  "Rights related to automated decision making including profiling"
                )}
              </li>
            </ul>
            <p>{t("privacyNotice")}</p>
            <p>{t("rightToCorrect")}</p>
            <p>
              {t("questionsAndComplaints")}{" "}
              <a href="mailto:privacy@qantev.com">privacy@qantev.com</a>
            </p>
            <p>
              {t(
                "Alternatively, if you are located in the European Union, you can also have recourse to the European Data Protection Supervisor or with your nation’s data protection authority."
              )}
            </p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>8. {t("Data storage and retention")}</h4>
            <p>
              {t(
                "Qantev does not store nor retain any personal data on its servers."
              )}
            </p>
            <p>
              {t(
                "For more information on where and how long your personal data is stored, and for more information on your rights of erasure and portability, please contact us at"
              )}{" "}
              <a href="mailto:privacy@qantev.com">privacy@qantev.com</a>
            </p>
          </PrivacyContainer.ContentSection>
          <PrivacyContainer.ContentSection>
            <h4>9. {t("Children’s data")}</h4>
            <p>
              {t(
                "We do not knowingly attempt to solicit or receive information from children."
              )}
            </p>
          </PrivacyContainer.ContentSection>

          <PrivacyContainer.ContentSection>
            <h4>10. {t("Questions, concerns or complaints")}</h4>
            <p>
              {t(
                "If you have questions, concerns, complaints, or would like to exercise your rights, please contact us at:"
              )}
            </p>
            <ul style={{ listStyleType: "none" }}>
              <li>Qantev</li>
              <li>10 rue Auber // 75009 // Paris, FR</li>
              <li>
                <a href="mailto:privacy@qantev.com">privacy@qantev.com</a>
              </li>
            </ul>
          </PrivacyContainer.ContentSection>
        </PrivacyContainer.Content>
      </PrivacyContainer>
    </PageContainer>
  );
};

export default PrivacyPolicyPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO
    title="Privacy Policy"
    description="Qantev privacy policy"
    pathname={pathname}
  />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["privacyPolicy", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
