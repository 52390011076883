import styled from "styled-components";

import BGDotTexture from "assets/images/bg-dot-texture.svg";

const PrivacyContainer = styled.div`
  padding: 10em calc(15vw / 2) 5em;

  background-image: url(${BGDotTexture}),
    linear-gradient(72deg, #1f2058 65%, #00033f);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;

  h4,
  a {
    font-size: 18px;
    color: white;
  }

  @media (min-width: 1024px) {
    padding: 10em calc(15vw / 2) 10em;
  }
`;

const PrivacyContent = styled.div``;

const PrivacyContentSection = styled.div`
  margin-bottom: 2em;

  p {
    font-size: 17px;
    line-height: 34px;
  }
`;

PrivacyContainer.Content = PrivacyContent;
PrivacyContainer.ContentSection = PrivacyContentSection;

export default PrivacyContainer;
